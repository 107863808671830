import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// new import
import CoinFlip from "../CoinFlip/CoinFlip";

export default function Home() {
  const navigate = useNavigate();
  const [result, setResult] = useState("");
  const [round, setRound] = useState(null);
  const [playData, setplayData] = useState(null);
  const [outcome, setOutcome] = useState("");
  const [isFlipping, setIsFlipping] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("user_info"));
  const [apiUrl, setapiUrl] = useState(null);

  useEffect(() => {
    setapiUrl(
      `https://itbeats.co/api/v1/rounds/users/${userInfo?.id}/active-round`
    );
    console.log(apiUrl);
  }, []);

  const flipCoin = () => {
    const winLoseArray = [1, 2, 3, 5, 6, 7, 9, 10, 11, 12];
    const randomIndex = Math.floor(Math.random() * winLoseArray.length);

    const selectedNumber = winLoseArray[randomIndex];

    const isEven = selectedNumber % 2 === 0;
    setResult(isEven ? "Win" : "Loss");
  };

  useEffect(() => {
    if (round !== null) {
      sendResult();
    }
  }, [result]);

  const sendResult = async () => {
    try {
      const response = await axios.put(
        `https://itbeats.co/api/v1/rounds/${round}`,
        {
          result: result,
        }
      );
      if (response?.data) {
        navigate(`/profile`);
      }
    } catch (error) {
      console.error("API error:", error.message);
    }
  };

  // const customButton = {
  //   width: "230px",
  //   height: "50px",
  //   borderRadius: "5px",
  //   padding: "10px 25px",
  //   fontFamily: "fantasy",
  //   letterSpacing: "4px",
  //   cursor: "pointer",
  //   transition: "all 0.3s ease",
  //   position: "relative",
  //   display: "inline-block",
  //   border: "none",
  //   background:
  //     "linear-gradient(0deg, rgba(251,33,117,1) 0%, rgb(255 255 0) 100%)",
  //   color: "#000",
  //   overflow: "hidden",
  //   boxShadow:
  //     "inset 2px 2px 2px 0px rgba(255,255,255,.5), 7px 7px 20px 0px rgba(0,0,0,.1), 4px 4px 5px 0px rgba(0,0,0,.1)",
  //   outline: "none",
  //   "&:hover": {
  //     boxShadow:
  //       "inset 2px 2px 2px 0px rgba(255,255,255,.5), 7px 7px 20px 0px rgba(0,0,0,.1), 4px 4px 5px 0px rgba(0,0,0,.1)",
  //     "& .dot": {
  //       transform: "translateX(0px)",
  //       opacity: "1",
  //     },
  //   },
  // };

  const checkActiveRound = () => {
    // endpoint is {{ _.base_url }}/api/v1/rounds/users/1/active-round
    handleApiCall();
    console.log(userInfo);
    setRound(null);
    setResult("");
  };

  const handleApiCall = async () => {
    try {
      const response = await axios.get(
        `https://itbeats.co/api/v1/rounds/users/${userInfo?.id}/active-round`
      );
      if (response?.data?.data?.payment_id) {
        // navigate(`/payment/${response?.data?.data?.payment_id}`);
        setRound(response?.data?.data?.id);
      } else {
        setRound(null);
        navigate(`/payment/${response?.data?.data?.payment_id}`);
      }
    } catch (error) {
      console.error("API error:", error.message);
    }
  };

  return (
    <>
      <div className="w-full md:h-[80vh] h-[40vh]">
        <img
          className="w-full h-full aspect-video"
          src="./images/HomeBanner.png"
          alt="img"
        />
      </div>
      <div className="text-white font-sans bg-[#060e4d]">
        <section className="bg-cover bg-center py-24 relative text-center">
          <button
            className="custom-btn btn-11 font-bold"
            // style={customButton}
            onClick={checkActiveRound}
          >
            Play Game<div className="dot"></div>
          </button>
          {/* <CoinFlip /> */}
        </section>

        {round && (
          <div className="coin-container text-center">
            <div className="coin"></div>
            {result ? (
              <p className={`result ${result.toLowerCase()}`}>
                You <span className="">{result}</span> the Round
              </p>
            ) : (
              <button
                className="custom-btn btn-11 font-bold"
                onClick={flipCoin}
                disabled={result}
                // style={customButton}
              >
                Flip Coin
              </button>
            )}

            {isFlipping && <p className="flipping">Flipping...</p>}
          </div>
        )}

        <div className="p-4 pt-10 bg-white">
          <img
            className="w-3/5 h-[500px] aspect-square mx-auto"
            src="./images/Sponsor.png"
            alt="sponsor"
          />
        </div>

        <div className="sponsor w-full text-center bg-white p-8 space-y-4">
          {/* <h1 className="text-2xl text-black font-bold">20 tk to play</h1> */}
          <h2 className="text-4xl font-semibold text-blue-500">About</h2>

          <img
            className="aspect-video w-1/5 h-24 mx-auto"
            src="./images/logo-black.png"
            alt="about logo"
          />

          <p className=" md:w-1/3 w-4/5 text-center mx-auto text-[#09095d]">
            Serving as the premier one-stop solution for automation and
            digitalisation services.
          </p>
        </div>

        <div></div>
      </div>
    </>
  );
}
