import React from "react";

import { useParams } from "react-router-dom";

export default function Webview() {
  const { url } = useParams();
  const decodedUrl = decodeURIComponent(url);

  return (
    <div>
      <iframe
        src={decodedUrl}
        title="Webview"
        frameborder="0"
        width="100%"
        height="800px"
        allowFullScreen
      ></iframe>
    </div>
  );
}
