// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// environment get from .env file

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDHwpwMf0xg0Ygu2wunSLj4ZlbPisjAZHY",
  authDomain: "itbeats-37554.firebaseapp.com",
  projectId: "itbeats-37554",
  storageBucket: "itbeats-37554.appspot.com",
  messagingSenderId: "775860930763",
  appId: "1:775860930763:web:c690b6c4641fdd2449734e",
  measurementId: "G-7FRB49EZTK",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
