import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const sandboxUrl = "https://secure.aamarpay.com/index.php";
export default function Payment() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    cus_name: "",
    cus_email: "",
    cus_phone: "",
    amount: 20,
  });

  const data = {
    store_id: "aamardigital",
    tran_id: Math.floor(Math.random() * 10000),
    success_url: "success/example",
    fail_url: "fail/example",
    cancel_url: "cancel/example",
    currency: "BDT",
    signature_key: "7b68b11b8e054d13c4429e3cde82f56c",
    desc: "Description",
    cus_add1: "Dhaka",
    cus_add2: "Dhaka",
    cus_city: "Dhaka",
    cus_state: "Dhaka",
    cus_postcode: "0",
    cus_country: "Bangladesh",
    type: "json",
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = { ...data, ...formData };
    try {
      const response = await axios.post(sandboxUrl, payload, {
        mode: "no-cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      const responseData = response.data;
      // const encodedUrl = encodeURIComponent(responseData.payment_url);
      window.location.href = responseData?.payment_url;
    } catch (error) {
      console.error("API error:", error.message);
    }
  };

  return (
    <>
      <div className="flex items-center justify-center p-12 bg-black h-[85vh]">
        <div className="mx-auto w-full max-w-[350px] ">
          <div className="mb-5">
            <h1 className="text-center text-2xl font-semibold text-[#fff]">
              Payment Details
            </h1>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="mb-3 block text-base font-medium text-[#fff]">
                Customer Name
              </label>
              <input
                type="text"
                name="cus_name"
                placeholder="cus_name"
                onChange={handleChange}
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#000] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>
            <div className="mb-4">
              <label className="mb-3 block text-base font-medium text-[#fff]">
                Customer Email
              </label>
              <input
                type="text"
                name="cus_email"
                placeholder="cus_email"
                onChange={handleChange}
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#000] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>

            <div className="mb-4">
              <label className="mb-3 block text-base font-medium text-[#fff]">
                Customer Phone
              </label>
              <input
                type="text"
                name="cus_phone"
                placeholder="cus_phone"
                onChange={handleChange}
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#000] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>

            <div className="mb-4">
              <label className="mb-3 block text-base font-medium text-[#fff]">
                Amount
              </label>

              <input
                type="text"
                name="amount"
                placeholder="amount"
                onChange={handleChange}
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#000] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>

            <button
              type="submit"
              className="hover:shadow-form w-full rounded-md bg-[url('/src/assets/images/global-nav-bg.png')] bg-[2px] border border-white text-white text-[20px] py-2 px-8 text-center font-semibold hover:text-white hover:border-white duration-300"
            >
              Pay
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
