import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Profile() {
  // get api call for profile
  const navigate = useNavigate();

  const [userData, setUserData] = useState({});
  const [roundInfo, setroundInfo] = useState([]);
  const getUser = (id) => `https://itbeats.co/api/v1/rounds/users/${id}`;

  useEffect(() => {
    const storedData = localStorage.getItem("user_info");

    if (storedData) {
      const parsedData = JSON.parse(storedData);
      if (parsedData?.id) {
        setUserData({
          name: parsedData?.name,
          email: parsedData?.email,
          organization: parsedData?.organization,
          designation: parsedData?.designation,
          phone: parsedData?.phone,
          id: parsedData?.id,
        });
        getUserInfoApi(parsedData?.id);
      }
    }
  }, []);

  const getUserInfoApi = async (id) => {
    const response = await axios.get(getUser(id));
    const data = await response.data;

    if (data?.data?.length > 0) {
      setroundInfo(data.data);
    }
  };
  const handleNavigate = () => {
    // Use history.push to navigate without a full page reload
    navigate("/edit-profile");
  };

  return (
    <>
      <section className="md:max-w-[700px] mx-auto m-4 p-8 min-h-[700px] mb-4  rounded shadow-lg text-2xl">
        <div className="text-center">
          <img
            src="./images/avatar.png"
            alt="User "
            className="rounded-full w-32 h-32 mx-auto mb-4"
          />
          <h2 className="text-2xl font-semibold mb-2">{userData?.name}</h2>
          {/* button  for edit profile */}
          <button
            type="button"
            onClick={handleNavigate}
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
          >
            Edit Profile
          </button>
        </div>

        <div className="mt-4">
          <p className="text-gray-700 mb-2">
            <strong>Name:</strong> {userData?.name}
          </p>
          <p className="text-gray-700 mb-2">
            <strong>Mobile:</strong> {userData?.phone}
          </p>
          <p className="text-gray-700 mb-2">
            <strong>Email:</strong> {userData?.email}
          </p>
          <p className="text-gray-700 mb-2">
            <strong>Organization:</strong> {userData?.organization}
          </p>
          <p className="text-gray-700 mb-2">
            <strong>Designation:</strong> {userData?.designation}
          </p>
          <p className="text-gray-700 mb-2">
            <strong>User Id:</strong> {userData?.id}
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8 ">
          {roundInfo?.map((round) => (
            <div className="flex flex-col justify-center relative  cursor-pointer">
              <div className="relative group">
                <div className="absolute -inset-1 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg blur opacity-25 group-hover:opacity-100 transition duration-1000 group-hover:duration-200"></div>
                <div
                  className={`relative px-2 py-4 bg-[#28a745] ${
                    round?.result == "win" ? "bg-[#28a745]" : "bg-[#dc3545]"
                  } text-white ring-1 ring-gray-900/5 rounded-lg leading-none  space-x-6`}
                >
                  <div className="space-y-2 text-center w-full">
                    <h5 class="text-2xl">Round id: {round?.id}</h5>
                    <h2 className="text-white text-6xl">
                      {round?.result == "win" ? "WIN" : "LOSS"}
                    </h2>
                  </div>
                  <div className=" grid grid-cols-2 gap-2 text-base m-0">
                    <p className="m-0">Status: {round?.gift_status}</p>
                    <p className="m-0">Play Status: {round?.play_status}</p>
                  </div>
                </div>
              </div>
            </div>
          ))} 
        </div>
      </section>
    </>
  );
}
