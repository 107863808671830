// import React from "react";
import { Link, NavLink } from "react-router-dom";
import React, { useState } from "react";

export default function Header({ isLoggedIn }) {
  const [isDropdownVisible, setDropdownVisibility] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisibility(!isDropdownVisible);
  };

  return (
    <header className="bg-gray-800 p-4">
      <div className="container mx-auto flex justify-between items-center">
        <h1 className="text-xl text-white font-bold flex items-center">
          <Link to="/">
            <img
              className="aspect-video w-24 h-16"
              src="./images/CompanyLogo.png"
              alt="logo"
            />
          </Link>
        </h1>
        {isLoggedIn && (
          <>
            <nav className="flex items-center relative">
              {/* image add here */}

              <Link
                className="text-white hover:text-[#ffb61c] px-4 flex gap-2 items-center "
                // to={"/profile"}
              >
                <img
                  className="w-10 h-10 rounded-full"
                  src="./images/avatar.png"
                  alt="avatar"
                  id="dropdownDefaultButton"
                  onClick={toggleDropdown}
                />
                {isDropdownVisible && (
                  <div
                    id="dropdown"
                    className="z-10 absolute top-[50px] right-0 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
                  >
                    <ul
                      class="py-2 text-gray-700 dark:text-gray-200  text-lg font-bold"
                      aria-labelledby="dropdownDefaultButton "
                    >
                      <Link
                        className="px-2 py-1 hover:bg-gray-300 flex hover:text-black"
                        to={"/profile"}
                      >
                        Profile
                      </Link>
                      <li
                        className="px-2 py-1 hover:bg-gray-300 hover:text-black"
                        onClick={() => {
                          localStorage.removeItem("user");
                          localStorage.removeItem("user_info");

                          window.location.href = "/";
                        }}
                      >
                        Logout
                      </li>
                    </ul>
                  </div>
                )}
              </Link>
            </nav>
          </>
        )}
        {!isLoggedIn && (
          <nav className="flex items-center">
            <Link
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
              to={"/login"}
            >
              Login/Register
            </Link>
          </nav>
        )}
      </div>
    </header>
  );
}

{
  /* <button id="dropdownDefaultButton" data-dropdown-toggle="dropdown" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">Dropdown button <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
</svg>
</button>

<!-- Dropdown menu -->
<div id="dropdown" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
    <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
      <li>
        <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Dashboard</a>
      </li>
      <li>
        <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Settings</a>
      </li>
      <li>
        <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Earnings</a>
      </li>
      <li>
        <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Sign out</a>
      </li>
    </ul>
</div> */
}
