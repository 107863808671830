import React from "react";

export default function Footer() {
  return (
    <footer className="bg-gray-800 md:p-4">
      <div className="container mx-auto text-center md:flex items-center ">
        <p className="text-gray-500 md:w-1/3 w-full py-2 text-xs md:text-base">
          Copyright IT Beats Limited
        </p>
        <img
          className="md:w-2/3 w-full h-9"
          src="./images/footer.jpeg"
          alt="footer"
        />
      </div>
    </footer>
  );
}
