import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../pages/Login/Login";
import Register from "../pages/Register/Register";
import Profile from "../pages/Profile/Profile";
import Home from "../pages/Home/Home";
import { ROUTES } from "./RouterConfig";
import Payment from "../pages/Payment/Payment";
import Webview from "../pages/Webview/Webview";
const Router = () => {
  const RouteWithRole = ({ Element }) => {
    return (
      <>
        <Element />
      </>
    );
  };
  return (
    <div>
      <Routes>
        <Route
          exact
          path={ROUTES.Root}
          element={<RouteWithRole Element={Home} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Login}
          element={<RouteWithRole Element={Login} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Register}
          element={<RouteWithRole Element={Register} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Home}
          element={<RouteWithRole Element={Home} />}
        ></Route>

        <Route
          exact
          path={ROUTES.Profile}
          element={<RouteWithRole Element={Profile} />}
        ></Route>
        <Route
          exact
          path={ROUTES.payment + "/:id"}
          element={<RouteWithRole Element={Payment} />}
        ></Route>

        <Route
          path={ROUTES.webview + "/:url"}
          element={<RouteWithRole Element={Webview} />}
        ></Route>
      </Routes>
    </div>
  );
};
export default Router;
