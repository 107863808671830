import React, { useState, useEffect } from "react";
import axios from "axios";

export default function Register() {
  const [userData, setUserData] = useState({});
  const getUser = (id) => `https://itbeats.co/api/v1/users/${id}`;

  useEffect(() => {
    const storedData = localStorage.getItem("user_info");

    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setUserData(parsedData);
      if (parsedData?.id) {
        setFormData({
          name: parsedData?.name,
          email: parsedData?.email,
          organization: parsedData?.organization,
          designation: parsedData?.designation,
        });
      }
    }
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    organization: "",
    designation: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      name: formData.name,
      phone: userData?.phone,
      email: formData.email,
      organization: formData.organization,
      designation: formData.designation,
    };

    try {
      const apiUrl = `https://itbeats.co/api/v1/users/${userData?.id}`;
      const response = await axios.put(apiUrl, data);

      if (response.data) {
        localStorage.setItem("user_info", JSON.stringify(response.data?.data));
        window.location.href = "/profile";
      }
    } catch (error) {
      console.error("API error:", error.message);
    }
  };

  return (
    <>
      <div className="flex items-center justify-center p-12 bg-black h-[100vh]">
        <div className="mx-auto w-full max-w-[350px] bg-black">
          <div className="mb-5">
            <h1 className="text-center text-2xl font-semibold text-[#fff]">
              UPDATE YOUR ACCOUNT
            </h1>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                for="name"
                className="mb-3 block text-base font-medium text-[#fff]"
              >
                Full Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Full Name"
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#000] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>
            <div className="mb-4">
              <label
                for="email"
                className="mb-3 block text-base font-medium text-[#fff]"
              >
                Email Address
              </label>
              <input
                type="text"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email"
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#000] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>
            <div className="mb-4">
              <label
                for="designation"
                className="mb-3 block text-base font-medium text-[#fff]"
              >
                Designation
              </label>
              <input
                type="text"
                name="designation"
                id="designation"
                value={formData.designation}
                onChange={handleChange}
                placeholder="Enter your designation"
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#000] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>

            <div className="mb-4">
              <label
                for="organization"
                className="mb-3 block text-base font-medium text-[#fff]"
              >
                Organization Name
              </label>
              <input
                type="text"
                name="organization"
                id="organization"
                value={formData.organization}
                onChange={handleChange}
                placeholder="Organization Name"
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#000] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>

            <div>
              <button
                type="submit"
                className="hover:shadow-form w-full rounded-md bg-[url('/src/assets/images/global-nav-bg.png')] bg-[2px] border border-[#81898c] text-[#81898c] text-[20px] py-2 px-8 text-center font-semibold hover:text-white hover:border-white duration-300"
              >
                Update Profile
              </button>
            </div>
            {/* <div>
              <p className="text-center text-[#fff] mt-4">
                Already have an account?
                <a
                  href="./Login.html"
                  className="text-[#6A64F1] hover:underline"
                >
                  Login
                </a>
              </p>
            </div> */}
          </form>
        </div>
      </div>
    </>
  );
}
