import React from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes/Router";
import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";

function App() {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  React.useEffect(() => {
    const token = localStorage.getItem("user_info");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <div>
      <BrowserRouter>
        <Header isLoggedIn={isLoggedIn} />
        <Router />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
